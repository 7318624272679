var mapZoom = 3;
function setZoom(){
    var screenWidth = $(window).width() * window.devicePixelRatio;
    var screenHeight = $(window).height() * window.devicePixelRatio;
    if (screenWidth < 767)
    {
        mapZoom = 2;
    }
    return mapZoom;
}
function runMap(){
    setZoom();
    google.maps.event.addDomListener(window, 'load', init);
    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: mapZoom,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(map_center_lat,map_center_lng), // New York
            //mapTypeId: google.maps.MapTypeId.HYBRID,
            //mapTypeControlOptions: {
            //    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            //    position: google.maps.ControlPosition.TOP_CENTER
	        //},
            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: mapStyles
            };

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            var mapElement = document.getElementById('gmap');
            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);
            var infoWindow = new google.maps.InfoWindow;
            var html;
            // Create markers.
            projects.forEach(function(project) {
                var html = '<div class="mapInfo">';
                    html += '<div class="mapHeader">';
                    html += '<h2>'+project.title+'</h2>';
                    html += '</div>';
                    html += '<p class="mapType">'+project.icon+project.typeText+'</p>';
                    html += '<a class="btn btn-primary btn-xs" href="'+project.detail+'">Learn more</a>';
                    //html += project.text;
                    html += '</div>';
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(project.lat,project.lng),
                    icon: icons[project.type],
                    map: map,
                    title: project.title
                });
                bindInfoWindow(marker, map, infoWindow, html);
            });
        }
}
if(Cookies.get('mapStatus')=='undefined'){
    var mapStatus = 0;
}else{
    var mapStatus = Cookies.get('mapStatus');
    if(mapStatus==1){
        showMap();
    }else{
        hideMap();
    }
}
$('.showMap').on('click',function(e){
    e.preventDefault();
    if(mapStatus){
        hideMap();
    }else{
        showMap();
    }
});
$('.showList').on('click',function(e){
    e.preventDefault();
    if(mapStatus==1){
        hideMap();
    }else{
        showMap();
    }
});
function showMap(){
    $('#gmap').addClass('active');
    $('.showMap').parent().addClass('active');
    $('.showList').parent().removeClass('active');
    $('.transactions-list').fadeOut();
    mapStatus = 1;
    Cookies.set('mapStatus', 1);
}
function hideMap(){
    $('#gmap').removeClass('active');
    $('.showMap').parent().removeClass('active');
    $('.showList').parent().addClass('active');
    $('.transactions-list').fadeIn();
    mapStatus = 0;
    Cookies.set('mapStatus', 0);
}
function bindInfoWindow(marker, map, infoWindow, html) {
      google.maps.event.addListener(marker, 'click', function() {
        infoWindow.setContent(html);
        infoWindow.open(map, marker);
        //infoWindowLearnMore();
      });
}
function infoWindowLearnMore(){
    $('.mapInfo a').on('click',function(e){
        $( "#projectDetail" ).removeClass('active');
        e.preventDefault();
        target = $(this).attr("href");
        $( "#projectDetail" ).load(target, function(){});
        $( "#projectDetail" ).addClass('active');
    });
}