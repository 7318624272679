		/* MINIGALLERY */
		
		$(function() {
			var _direction = 'left';
			$('#carousel').carouFredSel({
				direction: _direction,
				responsive: true,
				circular: true,
				items: {
					width: 250,
					height: '250',
					visible: {
						min: 2,
						max: 10
					}
				},
				scroll: {
					items: 1,
					duration: 1000,
					timeoutDuration: 1500,
					pauseOnHover: 'immediate',
					onEnd: function( data ) {
						_direction = ( _direction == 'left' ) ? 'right' : 'left';
						$(this).trigger( 'configuration', [ 'direction', _direction ] );
					}
				}
			});
		});
		
		/*end*/




var buttonStatus = false;
function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}

function checkFocus(target){
    $(target+' input').focus(function() {
        //alert( "Handler for .focus() called." );
        addFocus(target);
    });

}
function addFocus(target){
    if($('#focus').length == 0){
        $('<input>').attr({
    		type: 'hidden',
    		id: 'focus',
    		name: 'focus',
    		value: 'focus'}).appendTo(target);
    }
}



$(document).ready(function(){
    $('.marquee').marquee({
        allowCss3Support: true,
        delayBeforeStart: 0,
        speed: 70,
        //duration: 5000,
        //pauseOnHover: false,
        startVisible: true
        //duplicated: true
    });
    if($("#gmap").length) {
        runMap();
    }

    $('.photos').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 1500,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 3
            }
        }]
    });
    // edits Bart
    tabsHashListener();
});
$(window).on("load", function (e) {

	save_run_js_function('swerkCarousel');
	bottomPadding();

	add_js_input('#contact_form');
	add_js_input('#modal_form');
	checkFocus('#contact_form');
	checkFocus('#modal_form');


    if(window.validate_contact_form){
	validate_contact_form();
	}
    if(window.validate_modal_form){
	validate_modal_form();
	}
    /*
    cookieAkkoord = Cookies.get('cookieAkkoord');
    if(cookieAkkoord == undefined){
        $('.cookie').fadeIn(300);
    }
    $('.close_cookie').on('click',function(e){
        Cookies.set('cookieAkkoord', 'shown');
        $('.cookie').fadeOut(300);
    });*/

});
$(window).resize(function() {
    bottomPadding();
});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
function bottomPadding(){
    footerHeight = $('.footer-wrapper').outerHeight() + 'px';
    $('body').css("margin-bottom", footerHeight);
}
$(function() {
    var header = $(".navbar.fixed-top");

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            header.addClass("bg-white");
            header.addClass("bg-scrolled");
        } else {
            header.removeClass("bg-white");
            header.removeClass("bg-scrolled");
        }
    });

});
$('.toTop').on('click',function(e){
    e.preventDefault();
    //target = $(this).attr('href');
    //alert(target);
    //$.scrollTo(target, 800,{offset: function() { return {top:-70}; }});
    $.scrollTo(0, 800,{offset: function() { return {top:0}; }});
    //$('.scrollto').scrollTo(target[,duration][,settings]);
});
$('.blog-tile').on('click',function(e){
    link = $(this).find('.blog-tile-link').attr('href');
    // edit Bart
    if(link){
        window.location.href = $(this).find('.blog-tile-link').attr('href');
    }
});
$('.transaction').on('click',function(e){
    window.location.href = $(this).find('.transaction-detail-link').attr('href');
});
$('.team-member').on('click',function(e){
    window.location.href = $(this).find('.link-to-team-detail').attr('href');
});


// edits Bart
function tabsHashListener(){
    let url = location.href.replace(/\/$/, "");

  if (location.hash) {
    const hash = url.split("#");
    $('#myTab a[href="#'+hash[1]+'"]').tab("show");
    url = location.href.replace(/\/#/, "#");
    history.replaceState(null, null, url);
    setTimeout(() => {
      $(window).scrollTop(0);
    }, 400);
  }

  $('a[data-toggle="tab"]').on("click", function() {
    let newUrl;
    const hash = $(this).attr("href");
    if(hash == "#home") {
      newUrl = url.split("#")[0];
    } else {
      newUrl = url.split("#")[0] + hash;
    }
    newUrl += "/";
    history.replaceState(null, null, newUrl);
  });
}

$('.download').on('click',function(e){
    e.preventDefault();
    if($(this).hasClass('active')){
       $(this).removeClass('active');
    }else{
        $(this).addClass('active');
    }
    setButtonStatus();
    addHiddenFields();
});
function setButtonStatus(){
    buttonStatus = ($('.factsheet-dl').hasClass('d-none')) ? true : false;
    $('.download').each(function(i){
        if($(this).hasClass('active')){
            buttonStatus = true;
        }
    });
    if(buttonStatus){
        $('.factsheet-dl').removeClass('d-none');
    }else{
        $('.factsheet-dl').addClass('d-none');
    }
}
function addHiddenFields(){
    $('.selectedDownloads').remove();
    $('.download').each(function(i){
        if($(this).hasClass('active')){
            value = $(this).attr('id');
            $('#modal_form').append('<input type="checkbox" name="downloads[]" value="'+value+'" class="d-none selectedDownloads" checked="checked">');
        }
    });
}