function save_run_js_function(function_name)
{
	if (typeof window[function_name] == 'function')
	{
		window[function_name]();
	}
}

function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}